export const sesionActiva = () => {
    if (localStorage.getItem("token"))
        return true;
    return false;
}

export const iniciarSesion = async (usuario, clave, cb = () => {}) => {
    
    try {
        
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/login`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                usuario, clave
            })
        })
    
        const { token, error } = await response.json();
    
        if (error)
            return { error }

        guardarSesion(token);

        cb();

        return {}

    } catch (error) {
        return {
            error
        }
    }

}

const guardarSesion = (token) => {
    localStorage.setItem("token", token);
}

export const cerrarSesion = (cb = () => {}) => {
    localStorage.removeItem("token")
    cb();
}

export const obtenerUsuario = async () => {

    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/user`, {
        headers: {
            Authorization : localStorage.getItem("token")
        }
    });

    const data = await response.json();

    return data;

}