import React from 'react';

import { iniciarSesion, sesionActiva } from '#lib/Sesion';
import GlobalContext from '../contexts/Global';
import Modal from '#components/Modal';

class LoginPage extends React.Component {

    static contextType = GlobalContext;

    state = {
        form: {
            usuario: '',
            clave: ''
        },
        cargando: false,
        error: '',
        modalIsOpen: false
    }

    handleChange = e => {
        this.setState({
            form: { 
                ...this.state.form,
                [e.target.name] : e.target.value 
            }
        })
    }

    handleCloseModal = () => {
        this.setState({
            modalIsOpen: false,
            error: '',
            form: {
                ...this.state.form,
                clave: ''
            }
        })
    }

    handleSubmit = async (e) => {

        e.preventDefault();

        this.setState({ cargando: true })

        const { usuario, clave } = this.state.form;

        const { error } = await iniciarSesion(usuario, clave, ()=>{
            this.context.actions({
                type: 'setState',
                payload: { logged: sesionActiva() }
            })
            this.props.history.push('/nueva-factura');
        })

        if (error){
            this.setState({ error, cargando: false , modalIsOpen: true })
        }

    }

    render() {

        const { usuario, clave } = this.state.form;

        return (
            <div className="min-h-screen bg-custom-color-1 flex flex-col items-center justify-center">
                <h1 className="text-3xl font-bold my-10 text-white">Facturacion electronica a tu alcance</h1>
                <form onSubmit={this.handleSubmit} className="font-mono bg-white w-64 p-4 rounded-sm flex flex-col">
                    <h2 className="text-xl font-semibold text-custom-color-1 mb-4">Inicia sesión</h2>
                    <input className="py-2 px-3 w-full text-center font-semibold h-12 text-custom-color-1 border-b-2 border-custom-color-3 focus:border-custom-color-1 outline-none transition-all duration-500 text-md focus:text-lg" onChange={this.handleChange} value={usuario} type="text" name="usuario" placeholder="Nombre de usuario" required/>
                    <input className="py-2 px-3 w-full text-center font-semibold h-12 text-custom-color-1 border-b-2 border-custom-color-3 focus:border-custom-color-1 outline-none transition-all duration-500 text-md focus:text-lg" onChange={this.handleChange} value={clave} type="password" name="clave" placeholder="Contraseña" required/>
                    <button className="self-center mt-5 rounded-lg bg-custom-green-1 hover:bg-custom-green-2 focus:bg-custom-green-1 w-48 p-2 text-white focus:outline-none">
                        Iniciar sesion
                    </button>
                </form>
                <ModalMensaje contenido={this.state.error} isOpen={this.state.modalIsOpen} onClose={this.handleCloseModal} />
            </div>
        )
    };

}

const ModalMensaje = ({ contenido, isOpen, onClose }) => {

    return (<Modal isOpen={isOpen} onClose={onClose}>
        <div className="flex flex-col">
            <h2 className="my-2 font-bold text-red-700">Mensaje emergente</h2>
            <p className="my-1">Credenciales incorrectas. Escribalas nuevamente.</p>
            <button onClick={onClose} className="self-center font-semibold bg-custom-green-1 hover:bg-custom-green-2 text-white p-1 my-2 w-2/3">Aceptar</button>
        </div>
    </Modal>)

}

export default LoginPage;