const Documento = ({ documento, onClickActualizar, onClickReenviar }) => {
  const { claveAcceso, contenido, estado } = documento;
  const estaAutorizada = documento.estado === "AUTORIZADA";

  return (
    <div className="bg-gray-100 my-2 p-2 flex flex-col sm:flex-row flex-wrap">
      <div className="flex flex-col w-full md:w-1/2">
        <div className="flex flex-col justify-between w-full md:w-64">
          <h3 className="font-semibold text-gray-800">Clave de acceso</h3>
          <p className="break-words">{claveAcceso}</p>
        </div>
        <div className="flex flex-row justify-between w-full md:w-64">
          <h3 className="font-semibold text-gray-800">Cédula/RUC:</h3>
          <h3>{contenido.comprador.identificacionComprador}</h3>
        </div>
        <div className="flex flex-row justify-between w-full md:w-64">
          <h3 className="font-semibold text-gray-800">Nombres:</h3>
          <h3 className="ml-2 text-sm">
            {contenido.comprador.razonSocialComprador}
          </h3>
        </div>
        <div className="flex flex-row justify-between w-full md:w-64">
          <h3 className="font-semibold text-gray-800">Secuencial:</h3>
          <h3>{contenido.secuencial}</h3>
        </div>
        <div className="flex flex-row justify-between w-full md:w-64">
          <h3 className="font-semibold text-gray-800">Importe total:</h3>
          <h3>{contenido.importeTotal}</h3>
        </div>
        <div className="flex flex-row justify-between w-full md:w-64 items-center">
          <h3 className="font-semibold text-gray-800">Estado:</h3>
          <h3
            className={`py-1 px-2 rounded-sm font-semibold text-white ${coloresEstado[estado]}`}
          >
            {estado}
          </h3>
        </div>
      </div>
      <div className="flex flex-col w-1/2 items-end p-3 justify-between self-end md:self-auto">
        <div className="flex flex-col mb-2">
          <button
            title="Actualizar estado"
            className="my-1 disabled:bg-gray-200 bg-green-500 hover:bg-green-300 text-white w-10 h-10 rounded-lg"
            onClick={() => onClickActualizar(claveAcceso)}
            disabled={!["POR AUTORIZAR", "RECIBIDA"].includes(estado)}
          >
            <i className="fas fa-sync-alt"></i>
          </button>
          <button
            title="Reenviar factura"
            className="my-1 disabled:bg-gray-200 bg-green-500 hover:bg-green-300 text-white w-10 h-10 rounded-lg"
            onClick={() => onClickReenviar(claveAcceso)}
            disabled={!["NO RECIBIDA"].includes(estado)}
          >
            <i className="fas fa-arrow-alt-circle-right"></i>
          </button>
        </div>
        <a
          href={`/descargar-factura/${claveAcceso}`}
          target="_blank"
          rel="noreferrer"
        >
          <button
            disabled={!estaAutorizada}
            className="bg-custom-color-1 disabled:bg-blue-300 hover:bg-custom-color-3 text-white p-2 rounded-md font-semibold"
          >
            Descargar factura
          </button>
        </a>
      </div>
    </div>
  );
};

const coloresEstado = {
  INGRESADA: "bg-yellow-500",
  AUTORIZADA: "bg-green-500",
  PENDIENTE: "bg-yellow-500",
  RECIBIDA: "bg-blue-500",
  "NO RECIBIDA": "bg-red-400",
  "NO AUTORIZADA": "bg-red-500",
  "POR AUTORIZAR": "bg-blue-500",
};

export default Documento;
