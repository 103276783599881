import { Link } from "react-router-dom";

import FullScreenLoader from "#components/FullScreenLoader";
import Producto from "./Producto";
import { useVerProductos } from "./state/useVerProductos";

const VerProductos = () => {
  const { values, functions } = useVerProductos();

  return (
    <div className="container mx-auto p-1">
      {values.cargando && <FullScreenLoader />}
      <h2 className="text-custom-color-1 text-2xl font-bold my-4">
        Mis productos
      </h2>
      <div className="flex flex-row justify-between items-center mb-4">
        <input
          className="border border-gray-400 p-2 rounded-md"
          placeholder="Buscar producto..."
          value={values.filtroProducto}
          onChange={(e) => functions.setFiltroProducto(e.target.value)}
        />
        <Link
          to="/nuevo-producto"
          className="bg-green-500 p-2 rounded-md text-white"
        >
          <i className="fas fa-plus-circle"></i> Agregar producto
        </Link>
      </div>
      <div>
        {values.productos.map(({ codigo, descripcion, precio }) => (
          <Producto
            key={codigo}
            codigo={codigo}
            descripcion={descripcion}
            precio={precio}
          />
        ))}
      </div>
    </div>
  );
};

export default VerProductos;
