import { useState, useEffect } from "react";

const {
  consultarFacturas,
  actualizarEstadoFactura,
  reenviarFactura,
} = require("#lib/Facturacion");

export const useVerFacturas = () => {
  const [filtro, setFiltro] = useState("");
  const [facturas, setFacturas] = useState([]);
  const [facturasFiltradas, setFacturasFiltradas] = useState([]);
  const [error, setError] = useState(null);
  const [cargando, setCargando] = useState(false);

  const obtenerFacturas = async () => {
    setCargando(true);
    try {
      const { facturas } = await consultarFacturas();
      setFacturas(facturas);
      setFacturasFiltradas(facturas);
      setError(null);
    } catch (error) {
      setError(error);
    }
    setCargando(false);
  };

  const reenviarFacturas = async (claveAcceso) => {
    setCargando(true);
    try {
      await reenviarFactura(claveAcceso);

      obtenerFacturas();
    } catch (error) {
      setError(error);
      setCargando(false);
    }
  };

  const actualizarFacturas = async (claveAcceso) => {
    setCargando(true);
    try {
      await actualizarEstadoFactura(claveAcceso);
      obtenerFacturas();
    } catch (error) {
      setError(error);
      setCargando(false);
    }
  };

  useEffect(() => {
    obtenerFacturas();
  }, []);

  useEffect(() => {
    setFacturasFiltradas(filtrarFacturas());
  }, [filtro]);

  const filtrarFacturas = () => {
    return facturas.filter((factura) => {
      if (filtro === "") return true;

      return (
        factura.contenido.comprador.identificacionComprador === filtro ||
        factura.contenido.secuencial === filtro
      );
    });
  };

  const handleChangeFiltro = (e) => {
    setFiltro(e.target.value);
  };

  return {
    values: {
      facturas: facturasFiltradas,
      error,
      cargando,
      filtro,
    },
    functions: {
      handleChangeFiltro,
      reenviarFacturas,
      actualizarFacturas,
    },
  };
};
