import Modal from "#components/Modal";
import { useMemo, useState } from "react";

const ModalBuscarProducto = ({ isOpen, onClose, productos, onClick }) => {
  const [filtroProducto, setFiltroProducto] = useState("");

  const handleClick = (producto) => {
    onClick(producto);
    onClose();
  };

  const productosFiltrados = useMemo(() => {
    if (filtroProducto === "") return productos;

    const filtro = filtroProducto.trim().toLocaleLowerCase();

    return productos.filter(
      (producto) =>
        producto.descripcion.toLowerCase().includes(filtro) ||
        producto.codigo.toLowerCase().includes(filtro)
    );
  }, [productos, filtroProducto]);

  if (productos.length === 0) {
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <h2 className="font-bold">Productos registrados</h2>
        <div className="my-4">No hay productos registrados.</div>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="w-full md:w-800">
        <h2 className="font-bold">Productos registrados</h2>
        <div className="flex flex-row justify-between py-2">
          <p className="text-left">Seleccione un producto.</p>
          <input
            className="border border-gray-400 p-2 rounded-md"
            placeholder="Buscar producto..."
            value={filtroProducto}
            onChange={(e) => setFiltroProducto(e.target.value)}
          />
        </div>
        <div className="h-96 overflow-y-scroll flex flex-row flex-wrap">
          {productosFiltrados.map((producto) => (
            <div
              key={producto.codigo}
              onClick={() => handleClick(producto)}
              className="bg-gray-100 my-2 text-left cursor-pointer p-3 text-sm h-32 w-40 mr-2 box-border"
            >
              <div className="font-semibold">{producto.codigo}</div>
              <div>{producto.descripcion}</div>
              <div className="text-green-600">
                $ {producto.precio.toFixed(2)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default ModalBuscarProducto;
