import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const useLoginBar = () => {
  const [showOptions, setShowOptions] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setShowOptions(false);
  }, [history.location]);

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  return {
    values: {
      options,
      showOptions,
    },
    functions: {
      toggleOptions,
    },
  };
};

const options = [
  { logged: true, title: "Ver productos", url: "/mis-productos" },
  { logged: true, title: "Ver facturas", url: "/mis-facturas" },
  {
    logged: true,
    title: "Nueva Factura",
    url: "/nueva-factura",
    className:
      "bg-white text-custom-color-1 transition-colors delay-100 duration-300 hover:bg-custom-color-3 hover:text-white rounded-lg",
  },
  {
    logged: true,
    title: "Cerrar sesion",
    url: "/logout",
  },
  {
    logged: false,
    title: "Iniciar sesion",
    url: "/login",
  },
  // {
  //   logged: false,
  //   title: "Empezar a facturar",
  //   url: "/registrar",
  //   className:
  //     "p-1 mx-2 bg-white text-custom-color-1 transition-colors delay-100 duration-300 hover:bg-custom-color-3 hover:text-white rounded-lg",
  // },
];

export { useLoginBar };
