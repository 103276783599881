import FullScreenLoader from "#components/FullScreenLoader";
import { useVerFacturas } from "./state/useVerFacturas";
import Documento from "../../components/Documento";

const VerFacturas = () => {
  const { functions, values } = useVerFacturas();

  return (
    <div className="container mx-auto">
      {values.cargando && <FullScreenLoader />}
      <h2 className="text-custom-color-1 text-2xl font-bold my-4">
        Mis facturas
      </h2>
      {values.error && (
        <span className="text-red-400 my-2">
          No es posible realizar la solicitud en este momento, Intentelo
          nuevamente más tarde.
        </span>
      )}
      <div className="flex flex-row justify-end">
        <input
          className="w-64 bg-gray-100 border-gray-300 border rounded-md p-1 focus:outline-none"
          type="text"
          onChange={functions.handleChangeFiltro}
          placeholder="Buscar por cédula/RUC"
        />
      </div>
      <div>
        {values.facturas.map((factura) => (
          <Documento
            key={factura._id}
            documento={factura}
            onClickActualizar={functions.actualizarFacturas}
            onClickReenviar={functions.reenviarFacturas}
          />
        ))}
      </div>
    </div>
  );
};

export default VerFacturas;
