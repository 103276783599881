import { consultarProductos } from "#lib/Productos";
import { useEffect, useMemo, useState } from "react";

const useVerProductos = () => {
  const [cargando, setCargando] = useState(false);
  const [error, setError] = useState(null);
  const [productos, setProductos] = useState([]);
  const [filtroProducto, setFiltroProducto] = useState("");

  useEffect(() => {
    cargarProductos();
  }, []);

  const cargarProductos = async () => {
    setCargando(false);
    try {
      setCargando(true);
      const { productos } = await consultarProductos();
      setProductos(productos);
    } catch (error) {
      setError(error);
    }
    setCargando(false);
  };

  const obtenerProductosFiltrados = useMemo(() => {
    if (filtroProducto === "") return productos;

    const filtro = filtroProducto.trim().toLocaleLowerCase();

    return productos.filter(
      (producto) =>
        producto.descripcion.toLowerCase().includes(filtro) ||
        producto.codigo.toLowerCase().includes(filtro)
    );
  }, [productos, filtroProducto]);

  return {
    functions: {
      setFiltroProducto,
    },
    values: {
      cargando,
      error,
      productos: obtenerProductosFiltrados,
      filtroProducto,
    },
  };
};

export { useVerProductos };
