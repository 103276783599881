import currency from "currency.js";

import { obtenerIVA, obtenerSubtotal, obtenerTotal } from "#lib/Facturacion";

const MontosConsolidados = ({ productos }) => {
  return (
    <div className="border-2 w-2/5 md:w-1/3 lg:w-1/2 h-64 p-4 flex flex-col items-end">
      <h2 className="text-lg font-bold mb-4 text-gray-500 self-start">
        Monto de facturacion
      </h2>
      <div className="flex flex-row font-semibold text-lg w-full lg:w-auto justify-between items-end">
        <h3>Subtotal: </h3>
        <span className="w-auto md:w-32 lg:w-56 text-left md:text-right ml-10 text-lg md:text-xl lg:text-2xl text-blue-700">
          {currency(obtenerSubtotal(productos), {
            pattern: "! #",
          }).format()}
        </span>
      </div>
      <div className="flex flex-row font-semibold text-lg w-full lg:w-auto justify-between items-end">
        <h3>IVA: </h3>
        <span className="w-auto md:w-32 lg:w-56 text-left md:text-right ml-10 text-lg lg:text-xl text-red-700">
          {currency(obtenerIVA(productos), {
            pattern: "! #",
          }).format()}
        </span>
      </div>
      <div className="flex flex-row font-semibold text-xl w-full lg:w-auto justify-between items-end">
        <h3>Total: </h3>
        <span className="w-auto md:w-32 lg:w-56 text-left md:text-right ml-10 text-lg md:text-2xl lg:text-4xl text-custom-green-1">
          {currency(obtenerTotal(productos), {
            pattern: "! #",
          }).format()}
        </span>
      </div>
    </div>
  );
};

export default MontosConsolidados;
