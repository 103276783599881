import Modal from "#components/Modal";

const ModalRegistroConExito = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="w-64 text-black">
        <h2 className="font-bold text-xl my-1">
          <i className="fas fa-exclamation-circle"></i> Aviso{" "}
          <i className="fas fa-exclamation-circle"></i>
        </h2>
        <p>Factura enviada con exito</p>
        <div className="flex flex-row justify-around items-center my-2">
          <button
            className="p-2 w-20 bg-custom-green-1 hover:bg-custom-green-2 text-white rounded-lg"
            onClick={onClose}
          >
            Aceptar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalRegistroConExito;
