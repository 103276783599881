import React from "react";

class Home extends React.Component {
  render() {
    return (
      <div className="mx-auto container p-2 flex flex-col items-center">
        <h2 className="text-center my-2 md:my-4 font-bold text-custom-color-1 md:text-4xl w-3/4 md:w-1/2">
          FACTURACION ELECTRONICA PARA TU NEGOCIO A TU ALCANCE
        </h2>
        <ul className="px-8 my-2 md:my-8 list-disc text-md font-mono md:text-xl">
          <li className="my-4">
            Facturación al instante, con conexión al SRI.
          </li>
          <li className="my-4">
            Envio de factura en PDF y XML por correo electrónico.
          </li>
          <li className="my-4">De fácil uso, no requiere de instalación</li>
          <li className="my-4">
            No requiere de instalación. Funciona en tablets y computadora.
          </li>
          <li className="my-4">
            Actualizado para funcionar con la actualización del IVA a partir de
            abril de 2024.
          </li>
        </ul>
        <h3 className="font-bold my-10 text-2xl text-gray-600">PLANES</h3>
        <div className="flex flex-row justify-center">
          <div className="mx-3 md:mx-20 flex flex-col items-center justify-center w-32 md:w-40 h-32 md:h-40 bg-custom-color-1 text-white">
            <h4 className="text-center font-bold text-md md:text-xl">
              $ 5 <br />
              mensual
            </h4>
            <h5>Facturas ilimitadas</h5>
          </div>
          <div className="mx-3 md:mx-20 flex flex-col items-center justify-center w-32 md:w-40 h-32 md:h-40 bg-custom-color-1 text-white">
            <h4 className="text-center font-bold text-md md:text-xl">
              $ 50 <br />
              anual
            </h4>
            <h5>Facturas ilimitadas</h5>
          </div>
        </div>
        <h4 className="my-4 text-center font-semibold text-gray-700">
          Solicita un periodo de prueba de 30 días, TOTALMENTE GRATIS!
        </h4>
        <div className="bg-gray-100 p-2 w-3/4 text-center">
          <h3 className="font-semibold">Contáctanos para asesorarte</h3>
          <h5>
            <i className="fab fa-whatsapp-square w-10 text-green-500" /> 099 388
            7577
          </h5>
          <h6 className="text-sm">Ing. Wilmer Cedeño - Asesor Comercial</h6>
          <h6>Portoviejo - Ecuador</h6>
        </div>
        <div className="h-10"></div>
      </div>
    );
  }
}

export default Home;
