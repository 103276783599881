import { Link } from "react-router-dom";

import { useNuevoProducto } from "./state/useNuevoProducto";
import Modal from "../../components/Modal";

const NuevoProducto = () => {
  const { functions, values } = useNuevoProducto();

  return (
    <div className="mx-auto container p-2 flex flex-col">
      <form
        onSubmit={functions.handleSubmit}
        className="flex flex-col md:w-1/2"
      >
        <h2 className="text-xl font-bold">Registrar nuevo producto</h2>
        <div className="flex flex-col my-1">
          <label htmlFor="codigo">Código de producto</label>
          <input
            type="text"
            id="codigo"
            name="codigo"
            value={values.codigo}
            onChange={(e) => functions.setCodigo(e.target.value)}
            className="border border-gray-800 rounded-md px-1"
            required
          />
        </div>
        <div className="flex flex-col my-1">
          <label htmlFor="nombre">Descripción del producto</label>
          <input
            type="text"
            id="descripcion"
            name="descripcion"
            value={values.descripcion}
            onChange={(e) => functions.setDescripcion(e.target.value)}
            className="border border-gray-800 rounded-md px-1"
            required
          />
        </div>
        <div className="flex flex-col my-1">
          <label htmlFor="precio">Precio referencial</label>
          <input
            type="number"
            id="precio"
            name="precio"
            value={values.precio}
            onChange={(e) => functions.setPrecio(e.target.value)}
            min="0"
            className="border border-gray-800 rounded-md px-1"
            required
          />
        </div>
        <div className="flex flex-row justify-between my-1">
          <label htmlFor="impuesto">Impuesto</label>
          <select
            id="impuesto"
            name="impuesto"
            onChange={(e) => functions.setImpuesto(e.target.value)}
            value={values.impuesto}
            className="w-32 mx-1 border border-black rounded-md p-1"
          >
            <option value="IVA 15%" className="">
              IVA 15%
            </option>
            {/* <option value="IVA 14%" className="">
              IVA 14%
            </option>
            <option value="IVA 13%" className="">
              IVA 13%
            </option>
            <option value="IVA 12%" className="">
              IVA 12%
            </option> */}
            <option value="IVA 0%" className="">
              IVA 0%
            </option>
          </select>
        </div>
        <button className="bg-green-500 hover:bg-green-400 text-white my-2 p-2 self-center rounded-md">
          Guardar producto
        </button>
      </form>
      <ModalRegistroExitoso
        modalOpen={values.modalOpen}
        setModalOpen={functions.setModalOpen}
      />
    </div>
  );
};

const ModalRegistroExitoso = ({ modalOpen, setModalOpen }) => {
  const handleClose = () => setModalOpen(false);

  return (
    <Modal isOpen={modalOpen} onClose={handleClose}>
      <h2 className="font-bold mb-2">Mensaje</h2>
      <p>Se registro el producto de forma exitosa.</p>
      <div className="my-1 flex flex-col items-center">
        <button
          onClick={handleClose}
          className="w-48 bg-green-500 mt-2 p-1 rounded-md text-white"
        >
          Registrar otro producto
        </button>
        <Link
          to="/mis-productos"
          className="w-48 bg-blue-500 mt-2 p-1 rounded-md text-white"
        >
          Volver a Productos
        </Link>
      </div>
    </Modal>
  );
};

export default NuevoProducto;
