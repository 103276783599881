import Modal from "#components/Modal";
import { useEffect, useState } from "react";

const ModalInfoAdicional = ({ isOpen, onClose, infoAdicional, onClick }) => {
  const [newInfoAdicional, setInfoAdicional] = useState(
    JSON.parse(JSON.stringify(infoAdicional))
  );

  const addRow = () => {
    setInfoAdicional([...newInfoAdicional, { nombre: "", valor: "" }]);
  };

  const deleteRow = () => {
    setInfoAdicional(newInfoAdicional.slice(0, -1));
  };

  const actualizarInfoAdicional = (index, key, value) => {
    const newInfo = [...newInfoAdicional];
    newInfo[index][key] = value;
    setInfoAdicional(newInfo);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="w-96">
        <h2 className="font-bold">Información adicional</h2>
        <div className="flex flex-row justify-end my-2">
          <button
            onClick={addRow}
            className="bg-green-500 p-2 text-white rounded-md"
          >
            Nueva fila <i className="fas fa-plus-circle"></i>
          </button>
          <button
            onClick={deleteRow}
            className="bg-red-500 p-2 text-white rounded-md mx-2"
          >
            Borrar fila <i className="fas fa-minus-circle"></i>
          </button>
        </div>
        <div className="my-4">
          <div className="flex flex-row justify-between mb-2">
            <p className="font-semibold w-40 text-left">Nombre</p>
            <p className="font-semibold w-40 text-left">Valor</p>
          </div>
          {newInfoAdicional.length === 0 && (
            <div className="text-center">No hay información adicional.</div>
          )}
          {newInfoAdicional.map((inf, index) => (
            <div
              key={`${index}`}
              className="flex flex-row justify-between mb-1"
            >
              <input
                className="border border-gray-400 w-40"
                onChange={(e) =>
                  actualizarInfoAdicional(index, "nombre", e.target.value)
                }
                value={inf.nombre}
              />
              <input
                className="border border-gray-400 w-40"
                onChange={(e) =>
                  actualizarInfoAdicional(index, "valor", e.target.value)
                }
                value={inf.valor}
              />
            </div>
          ))}
        </div>
        <button
          onClick={() => onClick(newInfoAdicional)}
          className="bg-blue-500 hover:bg-blue-400 text-white w-32 p-2 font-bold rounded-md"
        >
          Guardar <i className="fas fa-save"></i>
        </button>
      </div>
    </Modal>
  );
};

export default ModalInfoAdicional;
