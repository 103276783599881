import jsbarcode from "jsbarcode";

export const generarCodigoBarra = (texto) => {
  const element = window.document.createElement("img");
  element.setAttribute("id", "barcode");

  jsbarcode(element, texto, {
    textMargin: 30,
  });

  //   jsbarcode(element).options({}).CODE128(texto, {
  //     textMargin: 30,
  //   });

  return element;
};
