const Producto = ({ index, producto, onDelete }) => {
  const {
    producto_codigo,
    producto_descripcion,
    producto_precio,
    producto_cantidad,
    producto_descuento,
    producto_impuesto,
  } = producto;

  const handleDelete = () => {
    onDelete(index);
  };

  return (
    <div key={index} className="flex flex-row font-bold text-sm">
      <div className="w-20 md:w-20 lg:w-32 m-1 py-2 px-1 bg-blue-300 truncate">
        {producto_codigo}
      </div>
      <div className="w-28 md:w-40 lg:w-64 m-1 py-2 px-1 bg-blue-300">
        {producto_descripcion}
      </div>
      <div className="w-14 md:w-20 lg:w-32 m-1 py-2 px-1 bg-blue-300">
        {producto_precio}
      </div>
      <div className="w-14 md:w-20 lg:w-32 m-1 py-2 px-1 bg-blue-300">
        {producto_cantidad}
      </div>
      <div className="w-14 md:w-20 lg:w-32 m-1 py-2 px-1 bg-blue-300">
        {producto_descuento}
      </div>
      <div className="w-20 md:w-20 lg:w-32 m-1 py-2 px-1 bg-blue-300">
        {producto_impuesto}
      </div>
      <div className="w-20 md:w-20 lg:w-32 m-1 py-2 px-1 bg-blue-300">
        {parseFloat(
          producto_precio * producto_cantidad - producto_descuento
        ).toFixed(2)}
      </div>
      <div
        onClick={handleDelete}
        className="w-16 m-1 font-bold p-1 text-red-600 cursor-pointer"
      >
        <i className="fas fa-times"></i>
      </div>
    </div>
  );
};

export default Producto;
