import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";

const Standard = (props) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <Header />
      <div className="min-h-screen flex flex-col">{props.children}</div>
      <Footer />
    </div>
  );
};

export default Standard;
