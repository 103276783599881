import { useState } from 'react';
import { sesionActiva } from '#lib/Sesion';

const GlobalState = () => {

    const [state, setState] = useState({ logged: sesionActiva() })

    const actions = (action) => {
        
        const { type, payload } = action;

        switch(type){
            case 'setState': return setState({
                ...payload
            });
            default: return state;
        }
    }

    return { state, actions };
}

export default GlobalState;