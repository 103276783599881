import React from "react";
import { useParams } from "react-router-dom";

import { useDescargarFactura } from "./state/useDescargarFactura";
import FullScreenLoader from "#components/FullScreenLoader";

const DescargarFactura = () => {
  const params = useParams();
  const { functions, values } = useDescargarFactura(params.clave);

  return (
    <div className="min-h-screen flex flex-row justify-center items-center">
      {values.descargando && <FullScreenLoader />}
      <div className="flex flex-col items-center">
        <h2 className="text-3xl font-bold py-10 w-80 text-custom-color-1">
          Click en el boton para descargar la factura
        </h2>
        <button
          onClick={functions.descargar}
          className="bg-custom-color-1 hover:bg-custom-color-3 text-white text-4xl p-4 w-48 rounded-lg"
        >
          <i className="fas fa-download"></i>
        </button>
      </div>
    </div>
  );
};

export default DescargarFactura;
