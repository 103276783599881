import { formatearImpuesto } from "./Impuestos";

import currency from "currency.js";

const obtenerSubtotal = (productos) => {
  let subtotal = currency(0);

  productos.forEach((producto) => {
    subtotal = subtotal.add(
      currency(
        currency(producto.producto_precio).multiply(producto.producto_cantidad)
          .value
      ).subtract(producto.producto_descuento)
    );
  });

  return subtotal.value;
};

const obtenerSubtotalIva = (productos) => {
  let subtotal = currency(0);

  productos
    .filter((producto) => producto.producto_impuesto !== "IVA 0%")
    .forEach((producto) => {
      subtotal = subtotal.add(
        currency(
          currency(producto.producto_precio).multiply(
            producto.producto_cantidad
          )
        ).subtract(producto.producto_descuento)
      );
    });

  return subtotal.value;
};

const obtenerSubtotalIvaCero = (productos) => {
  let subtotal = currency(0);

  productos
    .filter((producto) => producto.producto_impuesto === "IVA 0%")
    .forEach((producto) => {
      subtotal = subtotal.add(
        currency(
          currency(producto.producto_precio).multiply(
            producto.producto_cantidad
          )
        ).subtract(producto.producto_descuento)
      );
    });

  return subtotal.value;
};

const obtenerIVA = (productos) => {
  let iva = currency(0);

  productos
    .filter((producto) => producto.producto_impuesto !== "IVA 0%")
    .forEach((producto) => {
      iva = iva.add(
        currency(
          currency(producto.producto_precio).multiply(
            producto.producto_cantidad
          )
        )
          .subtract(producto.producto_descuento)
          .multiply(obtenerPorcentajeIVA(producto.producto_impuesto))
      );
    });

  return iva.value;
};

const obtenerPorcentajeIVA = (producto_impuesto) => {
  if (producto_impuesto === "IVA 0%") return 0;
  if (producto_impuesto === "IVA 12%") return 0.12;
  if (producto_impuesto === "IVA 13%") return 0.13;
  if (producto_impuesto === "IVA 14%") return 0.14;
  if (producto_impuesto === "IVA 15%") return 0.15;
};

const obtenerTotal = (productos) => {
  return currency(obtenerSubtotal(productos)).add(obtenerIVA(productos)).value;
};

const obtenerDescuentoTotal = (productos) => {
  let descuento = currency(0);

  productos.forEach((producto) => {
    descuento = descuento.add(producto.producto_descuento);
  });

  return descuento.value;
};

const formatearFactura = (formulario) => {
  return {
    factura: {
      ambiente: formulario.ambiente,
      tipoComprobante: "factura",
      totalSinImpuestos: obtenerSubtotal(formulario.productos),
      totalDescuento: obtenerDescuentoTotal(formulario.productos),
      subtotalIva: obtenerSubtotalIva(formulario.productos),
      subtotalIvaCero: obtenerSubtotalIvaCero(formulario.productos),
      iva: obtenerIVA(formulario.productos),
      importeTotal: obtenerTotal(formulario.productos),
      propina: 0,
      comprador: {
        obligadoContabilidad: formulario.obligado_contabilidad,
        tipoIdentificacion: formulario.tipo_identificacion,
        razonSocialComprador: formulario.comprador_nombres,
        identificacionComprador: formulario.comprador_cedula,
        direccionComprador: formulario.comprador_direccion,
        emailComprador: formulario.comprador_email,
      },
      productos: [
        ...formulario.productos.map((producto) => ({
          codigo: producto.producto_codigo,
          descripcion: producto.producto_descripcion,
          cantidad: producto.producto_cantidad,
          precioUnitario: parseFloat(producto.producto_precio),
          descuento: producto.producto_descuento,
          precioTotalSinImpuesto: currency(
            currency(producto.producto_precio).multiply(
              producto.producto_cantidad
            )
          ).subtract(producto.producto_descuento),
          detallesAdicionales: [],
          impuestos: [formatearImpuesto(producto.producto_impuesto, producto)],
        })),
      ],
      infoAdicional: [
        {
          _nombre: "Email",
          __text: formulario.comprador_email || "Sin correo",
        },
        ...formulario.infoAdicional.map((inf) => ({
          _nombre: inf.nombre,
          __text: inf.valor,
        })),
      ],
    },
    registrarComprador: formulario.registrar_comprador,
  };
};

const generarFactura = async (informacion) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/facturar`,
      {
        method: "POST",
        body: JSON.stringify(formatearFactura(informacion)),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

const reenviarFactura = async (claveAcceso) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/recepcion_manual`,
      {
        method: "POST",
        body: JSON.stringify({ claveAcceso }),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      }
    );

    const data = await response.json();

    if (response.status >= 400) throw new Error(data);

    return data;
  } catch (error) {
    throw error;
  }
};

const actualizarEstadoFactura = async (claveAcceso) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/autorizacion_manual`,
      {
        method: "POST",
        body: JSON.stringify({ claveAcceso }),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      }
    );

    const data = await response.json();

    if (response.status >= 400) throw new Error(data);

    return data;
  } catch (error) {
    throw error;
  }
};

const consultarPersona = async (identificacion) => {
  try {
    const response = await fetch(
      `${
        process.env.REACT_APP_BACKEND
      }/api/persona?token=${localStorage.getItem(
        "token"
      )}&identificacion=${identificacion}`
    );
    const data = await response.json();

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

const consultarFacturas = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/lista_facturas`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const data = await response.json();

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export {
  obtenerSubtotal,
  obtenerIVA,
  obtenerTotal,
  generarFactura,
  consultarPersona,
  consultarFacturas,
  actualizarEstadoFactura,
  reenviarFactura,
};
