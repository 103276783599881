const DatosDelCliente = ({
  state,
  handleChange,
  handleChangeCedula,
  handleConsultarPersona,
}) => {
  const {
    ambiente,
    comprador_cedula,
    comprador_nombres,
    comprador_direccion,
    comprador_email,
    tipo_identificacion,
  } = state.form;

  return (
    <div className="flex flex-col w-3/5 md:w-2/3 lg:w-1/2">
      <div className="absolute top-0 right-0 flex flex-row items-center mt-2 w-64">
        <label
          htmlFor="ambiente"
          className="hidden md:block font-semibold w-64"
        >
          Ambiente:
        </label>
        <select
          name="ambiente"
          id="ambiente"
          value={ambiente}
          onChange={handleChange}
          className="hidden md:block border-2 p-1"
          required
        >
          <option value="2" className="">
            PRODUCCION
          </option>
          <option value="1" className="">
            PRUEBAS
          </option>
        </select>
      </div>
      <div className="p-2 flex flex-row font-bold text-lg text-custom-color-1">
        <h2>Datos del cliente</h2>
      </div>
      <div className="p-2 flex flex-row">
        <label
          htmlFor="tipo_identificacion"
          className="font-semibold block w-48 md:w-64"
        >
          Identificacion:
        </label>
        <select
          name="tipo_identificacion"
          id="tipo_identificacion"
          value={tipo_identificacion}
          onChange={handleChange}
          className="border-2 p-1 w-48 md:w-64"
          required
        >
          <option value="" className="">
            Seleccione ...
          </option>
          <option value="04" className="">
            RUC
          </option>
          <option value="05" className="">
            CEDULA
          </option>
          <option value="06" className="">
            PASAPORTE
          </option>
          <option value="07" className="">
            VENTA A CONSUMIDOR FINAL
          </option>
          <option value="08" className="">
            IDENTIFICACION DEL EXTERIOR
          </option>
        </select>
      </div>
      <div className="p-2 flex flex-row">
        <label
          htmlFor="comprador_cedula"
          className="font-semibold block w-48 md:w-64"
        >
          Cedula:
        </label>
        <input
          type="text"
          name="comprador_cedula"
          id="comprador_cedula"
          onChange={handleChangeCedula}
          value={comprador_cedula}
          className="border-2 w-48 md:w-64"
          onKeyDown={(e) => {
            if (e.keyCode === 13) handleConsultarPersona(e);
          }}
          onBlur={(e) => {
            if (
              comprador_cedula.length === 10 ||
              comprador_cedula.length === 13
            )
              handleConsultarPersona(e);
          }}
          required
        />
      </div>
      <div className="p-2 flex flex-row">
        <label
          htmlFor="comprador_nombres"
          className="font-semibold block w-48 md:w-64"
        >
          Nombres/Apellidos:
        </label>
        <input
          disabled={!state.cedulaEsValida}
          type="text"
          name="comprador_nombres"
          id="comprador_nombres"
          onChange={handleChange}
          value={comprador_nombres}
          className="border-2 w-48 md:w-64"
          required
        />
      </div>
      <div className="p-2 flex flex-row">
        <label
          htmlFor="comprador_direccion"
          className="font-semibold block w-48 md:w-64"
        >
          Direccion:
        </label>
        <input
          disabled={!state.cedulaEsValida}
          type="text"
          name="comprador_direccion"
          id="comprador_direccion"
          onChange={handleChange}
          value={comprador_direccion}
          className="border-2 w-48 md:w-64"
          required
        />
      </div>
      <div className="p-2 flex flex-row">
        <label
          htmlFor="comprador_email"
          className="font-semibold block w-48 md:w-64"
        >
          Email:
        </label>
        <input
          disabled={!state.cedulaEsValida}
          type="text"
          name="comprador_email"
          id="comprador_email"
          onChange={(e) => {
            handleChange(e, false);
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              e.preventDefault();
              document.getElementById("producto_codigo").focus();
            }
          }}
          value={comprador_email}
          className="border-2 w-48 md:w-64"
          // NO NECESARIA PARA CONSUMIDOR FINAL
          required={tipo_identificacion !== "07"}
        />
      </div>
    </div>
  );
};

export default DatosDelCliente;
