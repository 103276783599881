import React from 'react';

import GlobalContext from '../contexts/Global';
import { cerrarSesion } from '#lib/Sesion';

class LogoutPage extends React.Component {
    
    static contextType = GlobalContext;

    componentDidMount(){
        cerrarSesion(()=>{
            this.context.actions({
                type: 'setState',
                payload: {
                    logged: false
                }
            })
        })
        this.props.history.push('/')
    }

    render() {
        return (
            <div>
                
            </div>
        )
    };

}

export default LogoutPage;