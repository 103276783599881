const Producto = ({ codigo, descripcion, precio }) => {
  return (
    <div className="bg-gray-100 my-2 p-1">
      <h2 className="font-bold">
        Codigo: <span className="font-semibold text-red-500">{codigo}</span>
      </h2>
      <h3 className="font-semibold">
        Precio: <span className="text-green-500">$ {precio.toFixed(2)}</span>
      </h3>
      <p className="p-1">{descripcion}</p>
    </div>
  );
};

export default Producto;
