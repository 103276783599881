import { useState } from "react";
import { generarPDF } from "./GenerarPDF";

export const useDescargarFactura = (claveAcceso) => {
  const [descargando, setDescargando] = useState(false);
  const [seDescargo, setSeDescargo] = useState(false);

  const descargar = async () => {
    setDescargando(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/factura_ride/${claveAcceso}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      const data = await response.json();

      if (data.error) throw new Error(data.error);

      generarPDF(data.factura);

      setSeDescargo(true);
    } catch (error) {
      alert(
        "La clave de acceso no es correcta o no se ha emitido desde este sistema. Si cree que es un error, consulte con el administrador del sistema."
      );
      console.log(error);
    }
    setDescargando(false);
  };

  return {
    functions: {
      descargar,
      setDescargando,
    },
    values: {
      descargando,
      seDescargo,
    },
  };
};
