import React from "react";
import { Link } from "react-router-dom";

import { useLoginBar } from "./state/useLoginBar";

const LoginBar = ({ logged }) => {
  const { functions, values } = useLoginBar();

  return (
    <React.Fragment>
      <div className="hidden md:flex flex-col md:flex-row font-semibold">
        {values.options
          .filter((option) => option.logged === logged)
          .map((option) => (
            <Link key={option.title} to={option.url}>
              <h2 className={`text-sm p-1 mx-2 ${option.className}`}>
                {option.title}
              </h2>
            </Link>
          ))}
      </div>
      <div className="flex md:hidden flex-col relative">
        <div
          onClick={functions.toggleOptions}
          className="w-10 border border-white text-lg text-center rounded-md cursor-pointer"
        >
          <i className="fas fa-ellipsis-h"></i>
        </div>
        {values.showOptions && (
          <div className="absolute z-10 top-12 right-0 bg-custom-color-1 p-1 w-32 rounded-md border border-white">
            {values.options
              .filter((option) => option.logged === logged)
              .map((option) => (
                <Link to={option.url}>
                  <h2 className={`text-sm p-1 mx-2 my-3 ${option.className}`}>
                    {option.title}
                  </h2>
                </Link>
              ))}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default LoginBar;
