
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import './styles/Modal.css';

function Modal(props){
    if(!props.isOpen){
        return null;
    }

    setTimeout(() => {
        const boton = document.querySelector('.modal-button')
        const input = document.querySelector('.focus-field')

        if(boton)
            boton.focus()

        if(input)
            input.focus()
    }, 0)

    return (
        ReactDOM.createPortal(
            <div className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-50 flex flex-col items-center justify-center">
                <div className="relative bg-white p-4 flex flex-col w-full sm:w-auto overflow-scroll sm:overflow-auto">
                    <button onClick={props.onClose} className="Modal__close-button w-12 sm:w-auto h-12 sm:h-auto hover:bg-red-500 hover:text-white font-bold focus:outline-none">
                        X
                    </button>
                    <div className="flex-1 flex flex-col justify-around mx-auto text-center">
                      {props.children}
                    </div>
                </div>
            </div>, 
            document.getElementById('modal')
        )
    );
}

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
}

export default Modal;