import React, { useContext } from "react";
import { Link } from "react-router-dom";
import LoginBar from "./LoginBar/LoginBar";

import GlobalContext from "../contexts/Global";

const Header = (props) => {
  const context = useContext(GlobalContext);

  const mostrarLogin = () => {
    return <LoginBar logged={context.state.logged} />;
  };

  return (
    <div className="flex flex-row items-center justify-between p-2 bg-custom-color-1 text-white">
      <Link to={`/`}>
        <h1 className="font-bold text-2xl ml-2">Factulito</h1>
      </Link>
      {mostrarLogin()}
    </div>
  );
};

export default Header;
