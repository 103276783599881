import React from "react";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";

import AppRoute from "./AppRoute";
import Standard from "./layouts/Standard";

import HomePage from "./pages/Home/Home";
import LoginPage from "./pages/LoginPage";
import NuevaFacturaPage from "./pages/NuevaFactura/NuevaFacturaPage";
import Page404 from "./pages/Page404";

import GlobalContext from "./contexts/Global";
import GlobalState from "#contexts/GlobalState";
import LogoutPage from "./pages/LogoutPage";
import DescargarFactura from "./pages/DescargarFactura/DescargarFactura";
import VerFacturas from "./pages/VerFacturas/VerFacturas";
import NuevoProducto from "./pages/NuevoProducto/NuevoProducto";
import VerProductos from "./pages/VerProductos/VerProductos";

const App = () => {
  const state = GlobalState();

  return (
    <GlobalContext.Provider value={state}>
      <BrowserRouter>
        <Switch>
          <AppRoute exact path="/" component={HomePage} layout={Standard} />
          {state.state.logged && (
            <AppRoute
              exact
              path="/nueva-factura"
              component={NuevaFacturaPage}
              layout={Standard}
            />
          )}
          {state.state.logged && (
            <AppRoute
              exact
              path="/mis-facturas"
              component={VerFacturas}
              layout={Standard}
            />
          )}
          {state.state.logged && (
            <AppRoute
              exact
              path="/mis-productos"
              component={VerProductos}
              layout={Standard}
            />
          )}
          {state.state.logged && (
            <AppRoute
              exact
              path="/nuevo-producto"
              component={NuevoProducto}
              layout={Standard}
            />
          )}
          {state.state.logged && (
            <Route exact path="/logout" component={LogoutPage} />
          )}
          {!state.state.logged && (
            <Route exact path="/login" component={LoginPage} />
          )}
          <Route
            exact
            path="/descargar-factura/:clave"
            component={DescargarFactura}
          />
          <Route exact path="/404" component={Page404} />
          <Redirect path="*" to="/404" />
        </Switch>
      </BrowserRouter>
    </GlobalContext.Provider>
  );
};

export default App;
