import Modal from "#components/Modal";

const ModalSinProductos = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="w-64 text-red-500">
        <h2 className="font-bold text-xl my-1">
          <i className="fas fa-exclamation-circle"></i> Aviso{" "}
          <i className="fas fa-exclamation-circle"></i>
        </h2>
        <p>Debe ingresar al menos un producto para poder emitir la factura.</p>
      </div>
    </Modal>
  );
};

export default ModalSinProductos;
