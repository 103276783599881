import React from 'react';
import ReactDOM from 'react-dom';

import Loader from 'react-loader-spinner';

const FullScreenLoader = ({ width = 100, height = 100 }) => {

    return (
        ReactDOM.createPortal(
            <div className="fixed z-50 top-0 left-0 right-0 bottom-0 bg-black opacity-75 flex flex-row justify-center items-center">
                <Loader 
                    type="Puff"
                    color="#00BFFF"
                    height={height}
                    width={width}
                    timeout={0}
                />
            </div>
        , document.getElementById("loader"))
    )

}

export default FullScreenLoader;