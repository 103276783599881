import Modal from "#components/Modal";
import { obtenerTotal } from "#lib/Facturacion";

const ModalConfirmacion = ({ isOpen, onClose, onConfirm, state }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="w-64 text-black">
        <h2 className="font-bold text-xl my-1">
          <i className="fas fa-exclamation-circle"></i> Aviso{" "}
          <i className="fas fa-exclamation-circle"></i>
        </h2>
        <p>
          Confirmar factura emisión de factura por el siguiente monto: <br />${" "}
          {obtenerTotal(state.form.productos).toFixed(2)}
        </p>
        <div className="flex flex-row justify-around items-center my-2">
          <button
            className="p-2 w-20 bg-custom-green-1 hover:bg-custom-green-2 text-white rounded-lg"
            onClick={onConfirm}
          >
            Facturar
          </button>
          <button className="p-2 w-20 text-red-600" onClick={onClose}>
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalConfirmacion;
