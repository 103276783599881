const registrarProducto = async (producto) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/productos`,
      {
        method: "POST",
        body: JSON.stringify(producto),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

const consultarProductos = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/lista_productos`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const data = await response.json();

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export { consultarProductos, registrarProducto };
