import { jsPDF } from "jspdf";
import { generarCodigoBarra } from "./CodigoBarras";

let doc;
export const generarPDF = (informacion) => {
  doc = new jsPDF();

  generarLogo(informacion.logo);
  generarRectangulos();

  agregarDatosContribuyente(informacion.comerciante);
  agregarInfoFacturacion(informacion);
  agregarDatosComprador(informacion);

  generarRectangulosProductos(informacion);
  generarRectangulosValoresConsolidados(informacion);
  generarRectangulosInformacionAdicional(informacion);

  doc.save(`${informacion.claveAcceso}.pdf`);
};

const generarLogo = (url) => {
  if (url) {
    return;
  }

  doc.setFont("arial", "normal", "bold");
  doc.setFontSize(28);
  doc.setTextColor("#F00");
  doc.text("NO TIENE LOGO", 10, 20);
};

const generarRectangulos = () => {
  doc.setFillColor(255, 255, 255);
  doc.rect(100, 10, 100, 110, "D");
  doc.rect(5, 55, 90, 65, "D");
  doc.rect(5, 125, 195, 25, "D");
};

const agregarDatosContribuyente = (informacion) => {
  doc.setTextColor("#000");
  doc.setFont("arial", "normal", "normal");
  doc.setFontSize(8);
  doc.text(8, 60, doc.splitTextToSize(informacion.nombreComercial, 80), {
    maxWidth: 80,
    align: "justify",
  });
  doc.text(8, 70, doc.splitTextToSize(informacion.nombreComercial, 80), {
    maxWidth: 80,
    align: "justify",
  });

  // direccion matriz
  doc.text(8, 80, doc.splitTextToSize("Dirección Matriz:", 20), {
    maxWidth: 20,
    align: "justify",
  });
  doc.text(25, 80, doc.splitTextToSize(informacion.direccionMatriz, 60), {
    maxWidth: 60,
    align: "justify",
  });

  // direccion sucursal
  doc.text(8, 90, doc.splitTextToSize("Dirección Sucursal:", 20), {
    maxWidth: 20,
    align: "justify",
  });
  doc.text(25, 90, doc.splitTextToSize(informacion.direccionSucursal, 60), {
    maxWidth: 60,
    align: "justify",
  });

  doc.text("OBLIGADO A LLEVAR CONTABILIDAD", 8, 105);
  doc.text(informacion.obligadoContabilidad, 80, 105);

  if (informacion.contribuyenteRimpe) {
    doc.text("CONTRIBUYENTE RÉGIMEN RIMPE", 8, 115);
  }
};

const agregarInfoFacturacion = (informacion) => {
  doc.setTextColor("#000");
  doc.setFont("arial", "normal", "normal");
  doc.setFontSize(14);

  doc.text(`R.U.C.:    ${informacion.comerciante.ruc}`, 105, 15);
  doc.text(`${informacion.tipoDocumento}`, 105, 22);

  doc.setFontSize(8);
  doc.text("No.", 105, 30);
  doc.text(
    `${numberWithZeros(informacion.numeroEstablecimiento, 3)}-${numberWithZeros(
      informacion.puntoEmision,
      3
    )}-${numberWithZeros(informacion.secuencial, 9)}`,
    115,
    30
  );

  doc.setFontSize(9);
  doc.text(`NÚMERO DE AUTORIZACIÓN`, 105, 38);
  doc.setFontSize(8);
  doc.text(informacion.claveAcceso, 105, 45);

  doc.setFontSize(9);
  doc.text(105, 55, doc.splitTextToSize(`FECHA Y HORA DE AUTORIZACIÓN:`, 60), {
    maxWidth: 30,
    align: "left",
  });
  doc.text(informacion.fechaAutorizacion, 140, 55);

  doc.text("AMBIENTE:", 105, 65);
  doc.text(informacion.ambiente, 140, 65);

  doc.text("EMISIÓN:", 105, 75);
  doc.text(informacion.tipoEmision, 140, 75);

  doc.text("CLAVE DE ACCESO", 105, 90);
  // generarCodigoBarra(informacion.claveAcceso);
  doc.addImage(
    generarCodigoBarra(informacion.claveAcceso),
    "PNG",
    104,
    94,
    90,
    20,
    "",
    "FAST"
  );
};

const agregarDatosComprador = (informacion) => {
  doc.setTextColor("#000");
  doc.setFont("arial", "normal", "normal");
  doc.setFontSize(9);

  doc.text(`Razón Social / Nombres y Apellidos:`, 10, 130);
  doc.text(informacion.comprador.razonSocial, 70, 130);

  doc.text(`Identificación`, 10, 135);
  doc.text(informacion.comprador.identificacion, 40, 135);

  doc.text(`Fecha`, 10, 140);
  doc.text(informacion.fecha, 40, 140);

  doc.text(`Dirección:`, 10, 145);
  doc.text(informacion.comprador.direccion, 40, 145);
};

const generarRectangulosProductos = (informacion) => {
  doc.setFillColor(255, 255, 255);
  // doc.rect(5, 155, 10, 10, "D");
  generarFilaProducto(155, {
    codPrincipal: "Cod. Principal",
    codAuxiliar: "Cod. Auxiliar",
    cantidad: "Cantidad",
    descripcion: "Descripción",
    detalleAdicional: "Detalle Adicional",
    precioUnitario: "Precio Unit.",
    subsidio: "Subsidio",
    precioSinSubsidio: "Precio sin Subsidio",
    descuento: "Descuento",
    precioTotal: "Precio Total",
  });
  informacion.productos.forEach((producto, index) => {
    generarFilaProducto(165 + index * 10, producto);
  });
};

const generarFilaProducto = (
  positionY,
  {
    codPrincipal,
    codAuxiliar,
    cantidad,
    descripcion,
    detalleAdicional,
    precioUnitario,
    subsidio,
    precioSinSubsidio,
    descuento,
    precioTotal,
  }
) => {
  const posYTexto = positionY + 3;
  const offsetXTexto = 1;

  doc.rect(5, positionY, 15, 10, "D");
  doc.rect(20, positionY, 15, 10, "D");
  doc.rect(35, positionY, 15, 10, "D");
  doc.rect(50, positionY, 30, 10, "D");
  doc.rect(80, positionY, 25, 10, "D");
  doc.rect(105, positionY, 20, 10, "D");
  doc.rect(125, positionY, 15, 10, "D");
  doc.rect(140, positionY, 20, 10, "D");
  doc.rect(160, positionY, 20, 10, "D");
  doc.rect(180, positionY, 20, 10, "D");

  doc.text(offsetXTexto + 5, posYTexto, doc.splitTextToSize(codPrincipal, 15));
  doc.text(offsetXTexto + 20, posYTexto, doc.splitTextToSize(codAuxiliar, 15));
  doc.text(offsetXTexto + 35, posYTexto, doc.splitTextToSize(cantidad, 15));

  if (descripcion.length > 55) {
    doc.setFontSize(6);
  } else if (descripcion.length > 40) {
    doc.setFontSize(7);
  } else {
    doc.setFontSize(8);
  }

  doc.text(offsetXTexto + 50, posYTexto, doc.splitTextToSize(descripcion, 30));
  doc.setFontSize(9);
  doc.text(
    offsetXTexto + 80,
    posYTexto,
    doc.splitTextToSize(detalleAdicional, 25)
  );
  doc.text(
    offsetXTexto + 105,
    posYTexto,
    doc.splitTextToSize(precioUnitario, 20)
  );
  doc.text(offsetXTexto + 125, posYTexto, doc.splitTextToSize(subsidio, 15));
  doc.text(
    offsetXTexto + 140,
    posYTexto,
    doc.splitTextToSize(precioSinSubsidio, 20)
  );
  doc.text(offsetXTexto + 160, posYTexto, doc.splitTextToSize(descuento, 20));
  doc.text(offsetXTexto + 180, posYTexto, doc.splitTextToSize(precioTotal, 20));
};

const generarRectangulosValoresConsolidados = (informacion) => {
  const initialPositionY = 160 + informacion.productos.length * 10 + 5;
  generarFilaValorConsolidado(
    initialPositionY,
    "SUBTOTAL 15%",
    informacion.facturacion.subtotalIva
  );
  generarFilaValorConsolidado(
    initialPositionY + 5,
    "SUBTOTAL 0%",
    informacion.facturacion.subtotalIvaCero
  );
  generarFilaValorConsolidado(
    initialPositionY + 10,
    "SUBTOTAL NO OBJETO DE IVA",
    informacion.facturacion.subtotalIvaCero
  );
  generarFilaValorConsolidado(
    initialPositionY + 15,
    "SUBTOTAL EXENTO DE IVA",
    informacion.facturacion.subtotalIvaCero
  );
  generarFilaValorConsolidado(
    initialPositionY + 20,
    "SUBTOTAL SIN IMPUESTOS",
    informacion.facturacion.totalSinImpuestos
  );
  generarFilaValorConsolidado(
    initialPositionY + 25,
    "TOTAL DESCUENTO",
    informacion.facturacion.totalDescuento
  );
  generarFilaValorConsolidado(
    initialPositionY + 30,
    "ICE",
    informacion.facturacion.ice || "0.00"
  );
  generarFilaValorConsolidado(
    initialPositionY + 35,
    "IVA 15%",
    informacion.facturacion.iva
  );
  generarFilaValorConsolidado(
    initialPositionY + 40,
    "TOTAL DEVOLUCION IVA",
    "0.00"
  );
  generarFilaValorConsolidado(initialPositionY + 45, "IRBPNR", "0.00");
  generarFilaValorConsolidado(initialPositionY + 50, "PROPINA", "0.00");
  generarFilaValorConsolidado(
    initialPositionY + 55,
    "VALOR TOTAL",
    informacion.facturacion.importeTotal
  );
};

const generarFilaValorConsolidado = (positionY, nombre, valor) => {
  const posYTexto = positionY + 3;
  const offsetXTexto = 1;

  doc.rect(125, positionY, 75, 5, "D");
  doc.rect(180, positionY, 20, 5, "D");

  doc.text(nombre, 125 + offsetXTexto, posYTexto);
  doc.text(200 - offsetXTexto, posYTexto, valor, {
    align: "right",
  });
};

const generarRectangulosInformacionAdicional = (informacion) => {
  let positionY = 168 + informacion.productos.length * 10;
  const offsetYTexto = 3;

  doc.rect(5, positionY, 115, 5, "D");
  doc.text(
    50,
    positionY + offsetYTexto,
    doc.splitTextToSize("Información Adicional", 115)
  );

  informacion.informacionAdicional.forEach((info, index) => {
    doc.rect(5, positionY + 5 + index * 5, 115, 5, "D");
    doc.text(
      10,
      positionY + offsetYTexto + 5 + index * 5,
      doc.splitTextToSize(`${info.nombre}:   ${info.valor}`, 115)
    );
  });
};

/**
 * Esta funcion sirve para rellenar de ceros hacia la izquierda un numero
 * @param {Number} n Numero
 * @param {Number} width Cantidad total de digitos
 * @param {Number} z
 * @returns
 */
function numberWithZeros(n, width, z) {
  z = z || "0";
  n = n + "";

  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
