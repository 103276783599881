import { registrarProducto } from "#lib/Productos";
import { useState } from "react";

export const useNuevoProducto = () => {
  const [codigo, setCodigo] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [precio, setPrecio] = useState("0");
  const [impuesto, setImpuesto] = useState("IVA 15%");
  const [modalOpen, setModalOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    agregarProducto();
  };

  const agregarProducto = async () => {
    const respuesta = await registrarProducto({
      codigo,
      descripcion,
      precio,
      impuesto,
    });

    if (respuesta.error) {
      return alert(respuesta.error);
    }

    setModalOpen(true);
  };

  return {
    functions: {
      handleSubmit,
      setCodigo,
      setImpuesto,
      setModalOpen,
      setPrecio,
      setDescripcion,
    },
    values: {
      codigo,
      descripcion,
      impuesto,
      modalOpen,
      precio,
    },
  };
};
