export const formatearImpuesto = (nombreImpuesto, producto) => {
  if (nombreImpuesto === "IVA 12%")
    return {
      codigo: 2,
      codigoPorcentaje: 2,
      tarifa: 12,
      baseImponible:
        producto.producto_precio * producto.producto_cantidad -
        producto.producto_descuento,
      valor: parseFloat(
        (
          (producto.producto_precio * producto.producto_cantidad -
            producto.producto_descuento) *
          0.12
        ).toFixed(2)
      ),
    };

  if (nombreImpuesto === "IVA 0%")
    return {
      codigo: 2,
      codigoPorcentaje: 0,
      tarifa: 0,
      baseImponible:
        producto.producto_precio * producto.producto_cantidad -
        producto.producto_descuento,
      valor: 0,
    };

  if (nombreImpuesto === "IVA 13%")
    return {
      codigo: 2,
      codigoPorcentaje: 10,
      tarifa: 13,
      baseImponible:
        producto.producto_precio * producto.producto_cantidad -
        producto.producto_descuento,
      valor: parseFloat(
        (
          (producto.producto_precio * producto.producto_cantidad -
            producto.producto_descuento) *
          0.13
        ).toFixed(2)
      ),
    };

  if (nombreImpuesto === "IVA 14%")
    return {
      codigo: 2,
      codigoPorcentaje: 3,
      tarifa: 14,
      baseImponible:
        producto.producto_precio * producto.producto_cantidad -
        producto.producto_descuento,
      valor: parseFloat(
        (
          (producto.producto_precio * producto.producto_cantidad -
            producto.producto_descuento) *
          0.14
        ).toFixed(2)
      ),
    };

  if (nombreImpuesto === "IVA 15%")
    return {
      codigo: 2,
      codigoPorcentaje: 4,
      tarifa: 15,
      baseImponible:
        producto.producto_precio * producto.producto_cantidad -
        producto.producto_descuento,
      valor: parseFloat(
        (
          (producto.producto_precio * producto.producto_cantidad -
            producto.producto_descuento) *
          0.15
        ).toFixed(2)
      ),
    };
};
