import React from 'react';

const Footer = (props) => {

    return (
        <div></div>
    );

}

export default Footer;